var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"detail-dialog base-info-dialog",attrs:{"title":"修改基本信息","centered":"","okText":"保存","cancelText":"取消"},on:{"cancel":_vm.handleHideThisModal,"ok":_vm.handleEditBaseInfoDialogOkClick},model:{value:(_vm.baseInfoDialogVisible),callback:function ($$v) {_vm.baseInfoDialogVisible=$$v},expression:"baseInfoDialogVisible"}},[_c('a-form',{attrs:{"form":_vm.baseInfoForm}},[_c('a-form-item',{attrs:{"label":"合作模式","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'co_op_mode',
            { initialValue: _vm.data.co_op_mode || 0 } ]),expression:"[\n            'co_op_mode',\n            { initialValue: data.co_op_mode || 0 },\n          ]"}],attrs:{"placeholder":"请选择"}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v("请选择")]),_vm._l((_vm.coopModeOptions),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])})],2)],1),_c('a-form-item',{attrs:{"label":"达人对接","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'star_docking_user',
            { initialValue: _vm.data.star_docking_user || undefined } ]),expression:"[\n            'star_docking_user',\n            { initialValue: data.star_docking_user || undefined },\n          ]"}],staticClass:"select-item",attrs:{"show-search":"","filter-option":_vm.filterOption,"placeholder":"请选择"}},[_c('a-select-option',{attrs:{"value":undefined}},[_vm._v("请选择")]),_vm._l((_vm.opeartors),function(p){return _c('a-select-option',{key:p.id,attrs:{"value":p.id}},[_vm._v(_vm._s(p.username))])})],2)],1),_c('a-form-item',{attrs:{"label":"平台对接","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'operate_docking_user',
            { initialValue: _vm.data.operate_docking_user || undefined } ]),expression:"[\n            'operate_docking_user',\n            { initialValue: data.operate_docking_user || undefined },\n          ]"}],staticClass:"select-item",attrs:{"show-search":"","filter-option":_vm.filterOption,"placeholder":"请选择"}},[_c('a-select-option',{attrs:{"value":undefined}},[_vm._v("请选择")]),_vm._l((_vm.opeartors),function(p){return _c('a-select-option',{key:p.id,attrs:{"value":p.id}},[_vm._v(_vm._s(p.username))])})],2)],1),_c('a-form-item',{attrs:{"label":"淘客PID归属","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'pid_attr',
            { initialValue: _vm.data.pid_attr || 0 } ]),expression:"[\n            'pid_attr',\n            { initialValue: data.pid_attr || 0 },\n          ]"}],attrs:{"placeholder":"请选择"}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v("请选择")]),_vm._l((_vm.pidAttrOptions),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])})],2)],1),(_vm.baseInfoForm.getFieldValue('pid_attr'))?_c('a-form-item',{attrs:{"label":"淘客PID","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'pid',
            { rules: _vm.validatorRules.pid, initialValue: _vm.data.pid } ]),expression:"[\n            'pid',\n            { rules: validatorRules.pid, initialValue: data.pid },\n          ]"}],attrs:{"placeholder":"请输入淘客PID"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"抖音UID","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'uid',
            { rules: _vm.validatorRules.uid, initialValue: _vm.data.uid } ]),expression:"[\n            'uid',\n            { rules: validatorRules.uid, initialValue: data.uid },\n          ]"}],attrs:{"placeholder":"请输入抖音UID","maxLength":20}})],1),_c('a-form-item',{attrs:{"label":"微信号","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['wx_account', { initialValue: _vm.data.wx_account }]),expression:"['wx_account', { initialValue: data.wx_account }]"}],attrs:{"placeholder":"请输入微信号"}})],1),_c('a-form-item',{attrs:{"label":"备注信息","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['note', { initialValue: _vm.data.note }]),expression:"['note', { initialValue: data.note }]"}],attrs:{"placeholder":"请输入备注","maxLength":100}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }