<!--
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-05 13:36:09
 * @LastEditTime: 2020-08-10 16:15:42
 * @Description: 修改用户基本信息 弹窗
 -->
<template>
  <div>
    <!-- 修改用户基本信息 弹窗 -->
    <a-modal
      class="detail-dialog base-info-dialog"
      title="修改基本信息"
      v-model="baseInfoDialogVisible"
      centered
      okText="保存"
      cancelText="取消"
      @cancel="handleHideThisModal"
      @ok="handleEditBaseInfoDialogOkClick"
    >
      <a-form :form="baseInfoForm">
        <a-form-item
          label="合作模式"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-select
            v-decorator="[
              'co_op_mode',
              { initialValue: data.co_op_mode || 0 },
            ]"
            placeholder="请选择"
          >
            <a-select-option :value="0">请选择</a-select-option>
            <a-select-option
              v-for="(item, index) in coopModeOptions"
              :value="item.value"
              :key="index"
              >{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="达人对接"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-select
            v-decorator="[
              'star_docking_user',
              { initialValue: data.star_docking_user || undefined },
            ]"
            show-search
            :filter-option="filterOption"
            class="select-item"
            placeholder="请选择"
          >
            <a-select-option :value="undefined">请选择</a-select-option>
            <a-select-option :value="p.id" v-for="p in opeartors" :key="p.id">{{ p.username }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="平台对接"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-select
            v-decorator="[
              'operate_docking_user',
              { initialValue: data.operate_docking_user || undefined },
            ]"
            show-search
            :filter-option="filterOption"
            class="select-item"
            placeholder="请选择"
          >
            <a-select-option :value="undefined">请选择</a-select-option>
            <a-select-option :value="p.id" v-for="p in opeartors" :key="p.id">{{
              p.username
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="淘客PID归属"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-select
            v-decorator="[
              'pid_attr',
              { initialValue: data.pid_attr || 0 },
            ]"
            placeholder="请选择"
          >
            <a-select-option :value="0">请选择</a-select-option>
            <a-select-option
                v-for="(item, index) in pidAttrOptions"
                :value="item.value"
                :key="index"
                >{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="淘客PID"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          v-if="baseInfoForm.getFieldValue('pid_attr')"
        >
          <a-input
            v-decorator="[
              'pid',
              { rules: validatorRules.pid, initialValue: data.pid },
            ]"
            placeholder="请输入淘客PID"
          />
        </a-form-item>

        <a-form-item
          label="抖音UID"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input
            v-decorator="[
              'uid',
              { rules: validatorRules.uid, initialValue: data.uid },
            ]"
            placeholder="请输入抖音UID"
            :maxLength="20"
          />
        </a-form-item>

        <a-form-item
          label="微信号"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input
            v-decorator="['wx_account', { initialValue: data.wx_account }]"
            placeholder="请输入微信号"
          />
        </a-form-item>

        <a-form-item
          label="备注信息"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
        >
          <a-textarea
            v-decorator="['note', { initialValue: data.note }]"
            placeholder="请输入备注"
            :maxLength="100"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { editUserInfo } from "@/service/user";
import { coopModeOptions, pidAttrOptions } from "@/const/index";

export default {
  name: "ModalUserBaseInfoData",
  props: {
    id: {
      default: ""
    },
    opeartors: {
      type: Array
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      validatorRules: {
        // 淘客PID
        pid: [
          {
            required: true,
            message: "请输入淘客PID",
          },
        ],

        // 抖音UID
        uid: [
          {
            pattern: /^\d{0,20}$/,
            message: "只能输入数字",
          },
        ],
      },
      baseInfoForm: this.$form.createForm(this),
      baseInfoDialogVisible: true,
      coopModeOptions, // 合作模式OPTION
      pidAttrOptions, // 淘客PID归属OPTION
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.baseInfoForm.setFieldsValue({ pid_attr: this.data.pid_attr });
    },
    closeModal() {
      this.$emit("callback");
    },
    // 修改状态
    handleChangeAuditStatus(e) {
      this.examine_status = e;
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = false) {
      this.$emit("callback", isNeedParent);
    },
    // 基本信息编辑弹窗点击确定按钮
    handleEditBaseInfoDialogOkClick() {
      this.baseInfoForm.validateFields(async (err, values) => {
        if (!err) {

          // 合作模式
          const params = Object.assign({}, values);

          // 对接人
          if (!params.star_docking_user) {
            params.star_docking_user = null;
          }
          if (!params.operate_docking_user) {
            params.operate_docking_user = null;
          }
          // 淘客PID归属
          if (!params.pid_attr) {
            params.pid = '';
          }

          const { err, res } = await editUserInfo(this.data.id, params);
          if (!err) {
            if (res.success) {
              this.$message.success("修改成功！");
              this.handleHideThisModal(true);
            }
          }
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
};
</script>
<style lang='less' scoped></style>
