<!--
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-05 13:36:09
 * @LastEditTime: 2020-06-23 19:00:03
 * @Description: 寄样申请审核弹层
 -->
<template>
  <div>
    <a-modal title="修改评级信息" :visible="true" centered @cancel="closeModal">
      <a-form :form="levelForm">
        <a-form-item
          v-if="data.channel === 2"
          label="主播昵称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input
            :maxLength="25"
            v-decorator="['nickname', { rules: [{ required: true, message: '请输入主播昵称' }] }]"
            placeholder="请填写主播昵称"
          />
        </a-form-item>
        <a-form-item
          v-if="data.channel === 1"
          label="抖音号"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input
            :maxLength="20"
            v-decorator="['douyin_account', { rules: [{ required: true, message: '请输入抖音号' }, { validator: checkNotChinese }] }]"
            placeholder="请填写抖音号"
          />
        </a-form-item>
        <a-form-item
          v-if="data.channel === 1"
          label="抖音等级"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-select
            placeholder="请选择"
            v-decorator="['taobao_douyin_level', { rules: [{ required: true, message: '请选择抖音等级' }] }]"
          >
            <a-select-option
              v-for="i in douyinLevelOption"
              :value="i.value"
              :key="i.value"
            >{{i.label}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="折一评级" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-select
            placeholder="请选择"
            v-decorator="['level', { rules: [{ required: true, message: '请选择折一评级' }] }]"
          >
            <a-select-option
              v-for="i in userLevelOption"
              :value="i.value"
              :key="i.value"
            >{{i.label}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal">取消</a-button>
        <a-button key="submit" type="primary" :loading="isPosting" @click="handleSubmitAudit">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { postUserLevel } from "@/service/user";
import { checkNotChinese } from "@/utils/validator";
import {
  douyinLevelOption,
  taobaoLevelOption,
  userLevelOption
} from "@/const/index.js";
export default {
  name: "ModalLevelChange",
  props: {
    id: {
      default: ""
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      levelForm: this.$form.createForm(this),
      examine_status: null,
      examine_remark: "",
      checkNotChinese,
      taobaoLevelOption,
      douyinLevelOption,
      userLevelOption
    };
  },
  mounted() {
    const data = this.data.user_level ? this.data.user_level : {};
    this.$nextTick(() => {
      this.levelForm.setFieldsValue(data);
    });
  },
  methods: {
    closeModal() {
      this.$emit("callback");
    },
    // 修改状态
    handleChangeAuditStatus(e) {
      this.examine_status = e;
    },
    // 判断是否有数据发生改变
    checkIfDataChange(values) {
      let isChanged = false;
      if (this.data.channel === 1) {
        // 抖音
        if (
          this.data.user_level.douyin_account !== values.douyin_account ||
          this.data.user_level.taobao_douyin_level !== values.taobao_douyin_level ||
          this.data.user_level.level !== values.level
        ) {
          isChanged = true;
        }
      } else {
        // 淘宝
        if (
          this.data.user_level.nickname !== values.nickname ||
          this.data.user_level.userid !== values.userid ||
          this.data.user_level.taobao_douyin_level !== values.taobao_douyin_level ||
          this.data.user_level.level !== values.level
        ) {
          isChanged = true;
        }
      }
      return isChanged;
    },
    // 修改评级信息提交数据
    handleSubmitAudit() {
      this.levelForm.validateFields(async (err, values) => {
        // 数据没改变，不提交数据
        if (this.checkIfDataChange(values)===false) {
          this.handleHideThisModal(true);
          return false
        }
        if (!err) {
          const data = values;
          const that = this;
          data.before_id =
            that.$props.data.user_level && that.$props.data.user_level.id;
          this.$confirm({
            title: "修改评级信息",
            content: () => <div>确认修改该用户的评级信息？</div>,
            async onOk() {
              that.isPosting = true;
              const { err, res } = await postUserLevel(data);
              that.isPosting = false;
              if (!err) {
                if (res.success) {
                  that.$message.success(res.message);
                  that.handleHideThisModal(true);
                }
              }
            }
          });
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = false) {
      this.$emit("callback", isNeedParent);
    }
  }
};
</script>
<style lang='less' scoped>
</style>
