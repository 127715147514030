<!--
 * @Description: 变更记录
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-06-29 16:14:52
 -->
 <template>
  <div class="account-modal">
    <a-drawer
      title="变更记录"
      width="1050px"
      placement="right"
      @close="handleHideThisModal"
      :visible="changeListDrawerVisible"
    >
      <a-card size="small" :title="'用户管理 / '+user" class="mb-20">
        <a-table
          v-auth="'user_manage_modify_user_base_info'"
          :rowKey="record => record.id"
          :columns="changeListColumns"
          :dataSource="list"
          :pagination="pagination"
          :loading="listLoading"
          :locale="{ emptyText: '暂无变更记录' }"
          @change="handleTableChange"
        >
          <!-- 原有评级 -->
          <template slot="before_level_slot" slot-scope="record">
            <span v-if="record.before_level" color="orange">LV{{record.before_level}}</span>
            <span v-else>-</span>
          </template>
          <!-- 审核结果 -->
          <template slot="level_slot" slot-scope="record">
            <span v-if="record.level" color="orange">LV{{record.level}}</span>
            <span v-else>-</span>
          </template>
          <!-- 变更类型 -->
          <template slot="change_type" slot-scope="record">
            <a-tag v-if="record.create_type===1" color="green">用户提交申请</a-tag>
            <a-tag v-if="record.create_type===2" color="blue">手动修改</a-tag>
            <a-tag v-if="record.create_type===3" color="orange">评级审核</a-tag>
          </template>
          <template slot="operation" slot-scope="record">
            <a-button
              size="small"
              @click="showChangeDetail(record.auth_type,record.get_change_json)"
              v-auth="'user_manage_audit_user'"
            >查看</a-button>
          </template>
        </a-table>
      </a-card>
    </a-drawer>
    <ModalLevelChangeDetail v-if="changeDetailModalVisible" :data="currentChangeDetailList" @callback="hideChangeDetailModal" />
  </div>
</template>
<script>
// 变更记录列表
const changeListColumns = [
  {
    title: "原有评级",
    align: "center",
    scopedSlots: { customRender: "before_level_slot" }
  },
  {
    title: "变更后评级",
    scopedSlots: { customRender: "level_slot" },
    align: "center"
  },
  {
    title: "变更类型",
    align: "center",
    scopedSlots: { customRender: "change_type" },
    width: 100
  },
  {
    title: "操作人",
    dataIndex: "audit_user_name",
    width: 110
  },
  {
    title: "操作时间",
    dataIndex: "create_time",
    width: 160,
    align: "center"
  },
  {
    title: "操作",
    align: "center",
    width: 260,
    scopedSlots: { customRender: "operation" }
  }
];
import { getUserLevelList } from "@/service/user.js";
import ModalLevelChangeDetail from '@/components/admin/user/modal-level-change-detail.vue'
export default {
  name: "DrawerChangeList",
  components: {
    ModalLevelChangeDetail
  },
  props: {
    user: {
      type: Number
    }
  },
  data() {
    return {
      listLoading: false,
      changeListColumns,
      changeListDrawerVisible: true,
      changeDetailModalVisible: false,
      list: [],
      currentAuthType: null,
      currentChangeDetailList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  mounted() {
    this.getChangeList();
  },
  methods: {
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
    // 获取变更记录
    async getChangeList() {
      this.listLoading=true;
      const params = { user: this.user, create_type__gte: 2 };
      const { err, res } = await getUserLevelList(params);
      this.listLoading=false;
      if (!err) {
        if (res.success) {
          this.list = res.data.results;
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
    },
    // 显示变更详情modal
    showChangeDetail(authType, data) {
      this.currentAuthType = authType;
      this.currentChangeDetailList = data;
      this.changeDetailModalVisible = true;
    },
    // 隐藏变更详情modal
    hideChangeDetailModal(){
      this.changeDetailModalVisible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.iframe-auto {
  width: 380px;
  margin: 0 auto 20px;
}
.n-table {
  th,
  td {
    font-weight: normal;
    padding: 5px;
  }
  th {
    text-align: right;
  }
}
.pic-box {
  img {
    height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.fullwidth-chart {
  width: 100%;
}
</style>
