<!--
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-05 13:36:09
 * @LastEditTime: 2020-06-18 15:22:47
 * @Description: 变更详情
 -->
<template>
  <div>
    <a-modal title="变更详情" :visible="true" centered @cancel="closeModal">
      <table class="bd-table">
        <thead>
          <tr>
            <th></th>
            <th>变更前</th>
            <th>变更后</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data" :key="i">
            <td>{{i.label}}</td>
            <td>{{codeToLevelStr(i.old)}}</td>
            <td>
              {{codeToLevelStr(i.new)}}
            </td>
          </tr>
        </tbody>
      </table>
      <template slot="footer">
        <a-button type="primary" @click="closeModal">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ModalLevelChangeDetail",
  props: {
    data: {
      type: Array
    },
    authType: {
      type: Number
    }
  },
  methods: {
    closeModal() {
      this.$emit("callback");
    },
    // 数字转字符串
    codeToLevelStr(code) {
      let str = "";
      if (typeof code === "string") {
        str = code;
      } else {
        if (this.authType === 1) {
          // 抖音
          if(code){
            str = `LV${code}`
          } else {
            str = `-`
          }
        } else {
          // 淘宝
          if(code){
            str = `V${code}`
          } else {
            str = `-`
          }
        }
      }
      return str;
    }
  }
};
</script>
<style lang="less" >
.bd-table {
  width: 100%;
  th,
  td {
    padding: 5px;
    border: 1px solid #ccc;
    text-align: center;
  }
  th {
    text-align: center;
    background-color: #f4f4f4;
  }
}
</style>