<!--
 * @Description: 评级审核
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-06-19 16:27:56
 -->
 <template>
  <div class="drawer-chart-detail-modal">
    <a-drawer
      :title="drawerTitle"
      width="1250px"
      placement="right"
      @close="handleHideThisModal"
      :visible="homePreviewDrawerVisible"
    >
      <!--历史绑定-->
      <a-card size="small" title="账号信息" class="mb-20" v-if="drawerType === 'history'">
        <ul class="account-info-list" v-if="douyinData">
          <li>抖音昵称：{{douyinData.nickname}}</li>
          <li style="width:700px">{{douyinData.bind_start_time}} ~ {{douyinData.bind_end_time}}</li>
          <li>粉丝数量：{{douyinData.fans_count === -1 ? '暂无数据' : douyinData.fans_count}}</li>
          <li>性别：{{genderType[douyinData.gender]}}</li>
          <li>地区：{{douyinData.province}}{{douyinData.city}}</li>
          <li>企业号类型：{{enterpriseType[douyinData.e_account_role]}}</li>
        </ul>
      </a-card>
      <a-card size="small" title="视频情况" class="mb-20">
        <div class="time-picker-bar">
          日期：
          <a-range-picker
            class="mr-20"
            :value="videoDate"
            :format="dateFormat"
            :disabled-date="disabledDate"
            @change="changeVideoDate"
          />
          <a-button type="text" class="mr-20" @click="setFixedDateRange(7, 'video')">近7天</a-button>
          <a-button type="text" class="mr-20" @click="setFixedDateRange(15, 'video')">近15天</a-button>
          <a-button type="text" class="mr-20" @click="setFixedDateRange(30, 'video')">近30天</a-button>
        </div>
        <div>
          <v-chart class="fullwidth-chart" :options="videoChartData" width="1000" />
        </div>
      </a-card>

      <a-card size="small" title="粉丝/互动" class="mb-20">
        <div class="time-picker-bar">
          日期：
          <a-range-picker
            class="mr-20"
            :value="fansDate"
            :format="dateFormat"
            :disabled-date="disabledDate"
            @change="changeFansDate"
          />
          <a-button type="text" class="mr-20" @click="setFixedDateRange(7, 'fans')">近7天</a-button>
          <a-button type="text" class="mr-20" @click="setFixedDateRange(15, 'fans')">近15天</a-button>
          <a-button type="text" class="mr-20" @click="setFixedDateRange(30, 'fans')">近30天</a-button>
        </div>
        <div>
          <v-chart class="fullwidth-chart" :options="fansChartData" width="1000" />
        </div>
      </a-card>

      <a-card size="small" title="粉丝画像">
        <ul class="fans-chart">
          <li v-for="i in douyinFansData" :key="i">
            <v-chart class="fullwidth-chart" :options="convertDataForPie(i)" />
          </li>
        </ul>
      </a-card>
    </a-drawer>
  </div>
</template>
<script>
import moment from "moment";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import { chartColorOption } from "@/const/";
import {
  getDouyinVideoData,
  getDouyinFansandplayData,
  getDouyinFansData,
  getDouyinChannelInfo
} from "@/service/user.js";
import {enterpriseType, genderType} from '@/const/index.js'

export default {
  name: "DrawerChartDetail",
  components: {},
  props: {
    data: {
      type: Object
    },
    union_id: {
      default: '0',
      type: String
    },
    drawerType: {
      default: 'detail',
      type: String
    }
  },
  data() {
    return {
      moment,
      chartColorOption,
      enterpriseType,
      genderType,
      douyinData:{},
      homePreviewDrawerVisible: true,
      maxEndDate: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      videoDate: [
        moment()
          .subtract(8, "days")
          .format("YYYY-MM-DD"),
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ],
      fansDate: [
        moment()
          .subtract(8, "days")
          .format("YYYY-MM-DD"),
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ],
      dateFormat: "YYYY-MM-DD",
      videoData: {
        x: [],
        series: []
      },
      fansData: {
        x: [],
        series: []
      },
      douyinFansData: [] // 粉丝画像
    };
  },
  mounted() {
    this.getDouyinVideoData();
    this.getDouyinFansandplayData();
    this.getDouyinFansData(); // 粉丝画像
    if(this.drawerType === 'history'){
      this.getAccountDetail()
    }
  },
  watch: {
    videoDate: function() {
      this.getDouyinVideoData();
    },
    fansDate: function() {
      this.getDouyinFansandplayData();
    }
  },
  computed: {
    drawerTitle(){
      const str = this.drawerType === 'history' ? '历史绑定' : '详情数据';
      return str;
    },
    // 视频情况数据
    videoChartData() {
      const seriesCopy = JSON.parse(JSON.stringify(this.videoData.series));
      seriesCopy.forEach((i, idx) => {
        i.type = "line";
        i.smooth = true;
        i.itemStyle = {
          normal: {
            lineStyle: {
              color: chartColorOption[idx]
            }
          }
        };
        return i;
      });
      const vcd = {
        legend: {
          data: this.getSeriseNameArray(seriesCopy),
          y: "bottom"
        },
        color: chartColorOption,
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.videoData.x
        },
        yAxis: {
          type: "value"
        },
        series: seriesCopy
      };
      return vcd;
    },
    // 粉丝/互动
    fansChartData() {
      const seriesCopy = JSON.parse(JSON.stringify(this.fansData.series));
      seriesCopy.forEach(i => {
        i.type = "line";
        i.smooth = true;
        return i;
      });

      const vcd = {
        legend: {
          data: this.getSeriseNameArray(seriesCopy),
          y: "bottom"
        },
        color: chartColorOption,
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.fansData.x
        },
        yAxis: {
          type: "value"
        },
        series: seriesCopy
      };
      return vcd;
    },
    // 粉丝画像
    fansChartsData() {
      const charts = [];
      return charts;
    }
  },
  methods: {
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
    // 获取serise name
    getSeriseNameArray(s) {
      return s.map(i => i.name);
    },
    // 获取视频情况
    async getDouyinVideoData() {
      const params = {
        union_id: this.union_id,
        start_date: this.videoDate[0],
        end_date: this.videoDate[1]
      };
      const { err, res } = await getDouyinVideoData({ params });
      if (!err) {
        if (res.success) {
          this.videoData = res.data;
        }
      }
    },
    // 获取粉丝/互动情况
    async getDouyinFansandplayData() {
      const params = {
        union_id: this.union_id,
        start_date: this.fansDate[0],
        end_date: this.fansDate[1]
      };
      const { err, res } = await getDouyinFansandplayData({ params });
      if (!err) {
        if (res.success) {
          this.fansData = res.data;
        }
      }
    },
    // 获取粉丝画像
    async getDouyinFansData() {
      const params = {
        union_id: this.union_id
      };
      const { err, res } = await getDouyinFansData({ params });
      if (!err) {
        if (res.success) {
          this.douyinFansData = [...res.data];
        }
      }
    },
    // 设置禁用日期
    disabledDate(current) {
      return current && current > moment().subtract(1, "days");
    },
    // 设置时间
    setFixedDateRange(day, lineType) {
      switch (lineType) {
        case "video":
          this.videoDate.splice(
            0,
            1,
            moment()
              .subtract(day + 1, "days")
              .format("YYYY-MM-DD")
          );
          this.videoDate.splice(
            1,
            1,
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          );
          break;
        default:
          this.fansDate.splice(
            0,
            1,
            moment()
              .subtract(day + 1, "days")
              .format("YYYY-MM-DD")
          );
          this.fansDate.splice(
            1,
            1,
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          );
          break;
      }
    },
    // 视频情况修改时间
    changeVideoDate(v, vStr) {
      this.videoDate.splice(0, 1, vStr[0]);
      this.videoDate.splice(1, 1, vStr[1]);
    },
    // 粉丝/互动修改时间
    changeFansDate(v, vStr) {
      this.fansDate.splice(0, 1, vStr[0]);
      this.fansDate.splice(1, 1, vStr[1]);
    },
    // 原始数据转化为可用pie数据
    convertDataForPie(data) {
      const _legend = [];
      const _series = [];
      data.data.forEach(i => {
        if (i.hasOwnProperty("flow")) {
          _legend.push(i.flow);
          _series.push({
            name: i.flow,
            value: i.all_sum
          });
        } else {
          _legend.push(i.item);
          _series.push({
            name: i.item,
            value: i.value
          });
        }
      });

      const op = {
        color: chartColorOption,
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        title: {
          text: data.title,
          left: "left"
        },
        legend: {
          orient: "vertical",
          top: 30,
          left: 0,
          data: [..._legend]
        },
        series: [
          {
            name: data.title,
            center: [330, "50%"],
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [..._series]
          }
        ]
      };
      return op;
    },
    // 获取账号信息
    async getAccountDetail(){
      const params = {
        unionid: this.union_id
      }
      const {err, res} = await getDouyinChannelInfo(params);
      if (!err) {
        if(res.success){
          this.douyinData = res.data
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.iframe-auto {
  width: 380px;
  margin: 0 auto 20px;
}
.n-table {
  th,
  td {
    font-weight: normal;
    padding: 5px;
  }
  th {
    text-align: right;
  }
}
.pic-box {
  img {
    height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.fullwidth-chart {
  width: 100%;
}
.fans-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 500px;
    height: 500px;
  }
}
.account-info-list {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 250px;
  }
}
</style>
