<!--
 * @Description: 用户详情组件
 * @Date: 2019-12-30 18:13:33
 * @LastEditTime: 2020-10-14 11:52:14
 * @LastEditors: 矢车
 -->
<template>
  <div id="user-detail">
    <a-tabs type="card">
      <a-tab-pane tab="用户信息" key="1">
        <a-spin :spinning="baseInfoLoading">
          <a-card v-if="userinfo !== null" title="基本信息" class="mb-20">
            <a-button
              v-auth="'user_manage_modify_user_base_info'"
              type="link"
              slot="extra"
              @click="handleBaseInfoEditClick"
              >编辑</a-button
            >
            <a-row class="user-info">
              <a-col class="user-info-line" span="8">注册手机：{{ userinfo.mobile }}</a-col>
              <a-col class="user-info-line" span="8">注册时间：{{ userinfo.date_joined }}</a-col>
              <a-col class="user-info-line" span="8">微信号：{{ userinfo.wx_account }}</a-col>
              <a-col class="user-info-line" span="8">合作模式：{{ userinfo.co_op_mode | coopModeFilter }}</a-col>
              <a-col class="user-info-line" span="8">平台对接：{{ personIdToStr(userinfo.operate_docking_user) }}</a-col>
              <a-col class="user-info-line" span="8">达人对接：{{ personIdToStr(userinfo.star_docking_user) }}</a-col>
            <!--  <a-col class="user-info-line" span="8">淘客PID归属：{{ userinfo.pid_attr | pidAttrFilter }}</a-col>
              <a-col class="user-info-line" span="8">淘客PID：{{ userinfo.pid }}</a-col>-->
              <a-col class="user-info-line" span="8">抖音UID：{{ userinfo.uid }}</a-col>
              <a-col class="user-info-line" span="8">兴趣类目：{{ userinfo.good_categorys_list && userinfo.good_categorys_list.join(',') | dftValueFilter}}</a-col>
              <a-col class="user-info-line" span="24">备注信息：{{ userinfo.note }}</a-col>
            </a-row>
          </a-card>
        </a-spin>
        <a-spin :spinning="baseInfoLoading">
          <a-card v-if="userinfo !== null" title="评级信息">
            <div slot="extra">
              <a-button
                v-if="userinfo.user_level"
                v-auth="'user_manage_modify_level'"
                type="link"
                @click="handleModalLevelChangeShow"
                >修改评级</a-button
              >
              <a-button v-if="userinfo.user_level" type="link" @click="handleDrawerChangeListShow"
                >变更记录</a-button
              >
            </div>
            <div v-if="userinfo.user_level">
              <a-row >
                <a-col span="8">认证渠道：抖音</a-col>
                <a-col span="8">抖音等级：LV{{ userinfo.user_level.taobao_douyin_level }}</a-col>
                <a-col span="8">授权账号：{{ userinfo.user_level.nickname }}</a-col>
                <a-col span="8">抖音号：{{ userinfo.user_level.douyin_account }}</a-col>
                <a-col span="8">折一等级：LV{{ userinfo.user_level.level }}</a-col>
                <a-col span="8"
                  >白名单：{{ userinfo.user_level.is_join_white_list ? '是' : '否' }}</a-col
                >
                <a-col span="8">评级人：{{ userinfo.user_level.audit_user }}</a-col>
                <a-col span="8">评级时间：{{ userinfo.user_level.create_time }}</a-col>
              </a-row>
            </div>
            <div v-else>未评级</div>
          </a-card>
        </a-spin>
        <br />
        <a-spin :spinning="baseInfoLoading">
          <a-card v-if="userinfo" title="渠道信息">
            <div slot="extra" v-if="userinfo.auth">
              <span
                >数据来源：{{ userinfo.channel === 1 ? '抖音官方' : '萤火虫' }} 最后更新：{{
                  userinfo.auth && userinfo.auth.update_time
                }}</span
              >
              <a-button
                v-auth="'user_manage_unbound_channel'"
                type="link"
                @click="handleUnbind"
                v-if="userinfo.channel === 1"
                >解除绑定</a-button
              >
              <a-button type="link" @click="handleShowUserChart" v-if="userinfo.channel === 1"
                >详细数据</a-button
              >
            </div>

            <a-row class="user-info" v-if="userinfo.auth">
              <!--淘宝渠道-->
              <template v-if="userinfo.channel === 2">
                <a-col class="user-info-line" span="24">渠道：淘宝</a-col>
                <a-col class="user-info-line" span="8"
                  >主播昵称：{{ userinfo.auth && userinfo.auth.nickname }}</a-col
                >
                <a-col class="user-info-line" span="8"
                  >粉丝数量：{{
                    userinfo.auth && userinfo.auth.fans_count && userinfo.auth.fans_count === -1
                      ? '暂无数据'
                      : userinfo.auth.fans_count
                  }}</a-col
                >
                <a-col class="user-info-line" span="8"
                  >淘宝等级：{{
                    userinfo.auth && userinfo.auth.rank && userinfo.auth.rank === -1
                      ? '暂无数据'
                      : 'V' + userinfo.auth.rank
                  }}</a-col
                >
                <a-col class="user-info-line" span="8"
                  >淘宝userID：{{ userinfo.auth && userinfo.auth.userid }}</a-col
                >
                <a-col class="user-info-line" span="8"
                  >擅长类目：{{ userinfo.auth && userinfo.auth.areas }}</a-col
                >
              </template>

              <!--抖音渠道-->
              <template v-else>
                <a-col class="user-info-line" span="8">渠道：抖音</a-col>
                <a-col class="user-info-line" span="16"
                  >授权状态：
                  <a-tag v-if="userinfo.douyin_auth_expire" color="red">过期</a-tag>
                  <a-tag v-else color="green">有效</a-tag>
                </a-col>
                <a-col class="user-info-line" span="8"
                  >抖音昵称：{{ userinfo.auth && userinfo.auth.nickname }}</a-col
                >
                <a-col class="user-info-line" span="8"
                  >粉丝数量：{{
                    userinfo.auth && userinfo.auth.fans_count && userinfo.auth.fans_count === -1
                      ? '暂无数据'
                      : userinfo.auth.fans_count
                  }}</a-col
                >
                <a-col class="user-info-line" span="8"
                  >视频数量：{{ userinfo.auth && userinfo.auth.video_count }}</a-col
                >
                <a-col class="user-info-line" span="8" v-if="userinfo.auth"
                  >性别：{{ getSexStr(userinfo.auth.gender) }}</a-col
                >
                <a-col class="user-info-line" span="8"
                  >地区：{{ userinfo.auth && userinfo.auth.province
                  }}{{ userinfo.auth && userinfo.auth.city }}</a-col
                >
                <a-col class="user-info-line" span="8" v-if="userinfo.auth"
                  >企业号类型：{{ accountType(userinfo.auth.e_account_role) }}</a-col
                >
              </template>
            </a-row>
            <a-row v-else>无渠道数据</a-row>
            <div class="bind-history" v-if="userinfo.auth_history && userinfo.auth_history.length">
              <h3>历史绑定</h3>
              <a-row v-for="i in userinfo.auth_history" :key="i.id">
                <a-col class="user-info-line" span="6">
                  <a href="javascript:;" @click="showBindHistoryDrawer(i.unionid)">{{
                    i.nickname
                  }}</a>
                </a-col>
                <a-col class="user-info-line" span="18"
                  >{{ i.bind_start_time }} ~ {{ i.bind_end_time }}</a-col
                >
              </a-row>
            </div>
          </a-card>
        </a-spin>
        <br />
        <a-spin :spinning="addressLoading">
          <a-card title="收货信息">
            <!-- <a-button type="link" slot="extra" @click="handleAddressAddClick">新增</a-button> -->
            <a-button
              v-if="addressList.length > 0"
              type="link"
              slot="extra"
              @click="handleAddressReviewClick"
              >查看全部</a-button
            >
            <a-list v-if="defaultAddress">
              <!-- 只显示一条默认地址 -->
              <a-list-item>
                <a-list-item-meta>
                  <div slot="title">{{ defaultAddress.realname }} {{ defaultAddress.mobile }}</div>
                  <div slot="description">
                    {{ defaultAddress.province }} {{ defaultAddress.city }}
                    {{ defaultAddress.area }} {{ defaultAddress.address }}
                  </div>
                </a-list-item-meta>
                <!-- <a slot="actions" @click="handleAddressEditClick(address)"><a-icon type="edit" /></a> -->
              </a-list-item>
            </a-list>
          </a-card>
        </a-spin>
        <br />
        <a-card title="实名信息">
          <!-- <a-button type="link" disabled slot="extra">编辑</a-button> -->
          <a-row class="user-info">
            <a-col class="user-info-line" span="12" v-if="userinfo.name">真实姓名：{{userinfo.name}}</a-col>
            <a-col class="user-info-line" span="12" v-if="genderName">性别：{{genderName}}</a-col>
            <a-col class="user-info-line" span="12" v-if="userinfo.age">年龄：{{userinfo.age}}</a-col>
            <a-col class="user-info-line" span="12" v-if="userinfo.nation">民族：{{userinfo.nation}}</a-col>
            <!-- <a-col class="user-info-line" span="12">地区：xxx</a-col> -->
            <a-col
            v-if="userinfo.identity"
              class="user-info-line"
              span="12"
            >身份证号：{{userinfo.identity ? userinfo.identity.substr(0, 14) + '****' : ''}}</a-col>
            <a-col class="user-info-line" span="12" v-if="userinfo.issue_org">签发机关：{{userinfo.issue_org}}</a-col>
            <a-col class="user-info-line" span="12" v-if="userinfo.id_start_time">签发日期：{{userinfo.id_start_time}}</a-col>
            <a-col class="user-info-line" span="12" v-if="userinfo.id_end_time">失效日期：{{userinfo.id_end_time}}</a-col>
            <!-- <a-col class="user-info-line" span="12">开户行：{{userinfo.opening_bank}}</a-col>签发日期：2011-11-11 失效日期：2031-11-11 -->
            <!-- <a-col class="user-info-line" span="12">银行卡号：{{userinfo.bank_card_num}}</a-col> -->
            <!-- <a-col class="user-info-line" span="12">身份证照片：{{!userinfo.photo && !userinfo.back_photo ? '未上传' : ''}}</a-col> -->
            <a-col
              class="user-info-line"
              span="12"
              v-auth="'user_manage_read_user_identity_key_info'"
            >
              身份证照片：
              <a-button v-show="userinfo.photo" id="id-card-1-btn" type="link">查看正面</a-button>
              <span v-show="!userinfo.photo" type="link">未上传</span>
              <a-button v-show="userinfo.back_photo" id="id-card-2-btn" type="link"
                >查看反面</a-button
              >
              <span v-show="!userinfo.back_photo" type="link" style="margin-left:30px;"
                >未上传</span
              >
            </a-col>
          </a-row>
          <a-row v-show="false" v-if="userinfo.photo && userinfo.back_photo" :gutter="16">
            <a-col class="info" :span="12">
              <img id="id-card-1" class="id-card" :src="userinfo.photo" />
            </a-col>
            <a-col class="info" :span="12">
              <img id="id-card-2" class="id-card" :src="userinfo.back_photo" />
            </a-col>
          </a-row>
        </a-card>
        <br />
        <a-card v-if="userinfo !== null" title="财务信息">
          <a-button
            v-if="userinfo.bank && userinfo.bank_card_num && userinfo.opening_bank"
            type="link"
            slot="extra"
            @click="handleBankEditClick"
            >编辑</a-button
          >
          <a-row class="user-info">
            <a-col class="user-info-line" span="12">所属银行：{{ userinfo.bank }}</a-col>
            <a-col class="user-info-line" span="12">银行卡号：{{ userinfo.bank_card_num }}</a-col>
            <a-col class="user-info-line" span="12">开户行：{{ userinfo.opening_bank }}</a-col>
          </a-row>
        </a-card>
      </a-tab-pane>
      <a-tab-pane tab="访问记录" key="2">
        <a-table
          :rowKey="record => record.id"
          :columns="columns"
          :dataSource="interviewRecord"
          :pagination="pagination"
          :locale="{ emptyText: '暂无访问记录' }"
          :loading="recordLoading"
          @change="hanleTableChange"
        >
          <template slot="client" slot-scope="record">
            <a-tag color="green" v-if="record.project === 0">未知</a-tag>
            <a-tag color="green" v-if="record.project === 1">小程序</a-tag>
            <a-tag color="cyan" v-if="record.project === 2">Android</a-tag>
            <a-tag color="purple" v-if="record.project === 3">iOS</a-tag>
          </template>
          <!-- <template slot="project" slot-scope="record">{{showDeviceName(record.project)}}</template> -->
        </a-table>
      </a-tab-pane>
      <a-tab-pane tab="用户权限" key="3">
        <a-card title class="mb-20" size="small" v-if="userinfo">
          <template slot="extra" v-if="isRightEdit === false">
            <a-button
              type="link"
              @click="handleSwithUserRightStatus"
              v-auth="'user_manage_modify_user_right_info'"
              >编辑</a-button
            >
          </template>
          <a-form class="filter-condition-form">
            <a-form-item label="普通商品" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-radio-group
                v-if="isRightEdit"
                :options="userRightOptions"
                v-model="allow_sample"
              />
              <template v-if="!isRightEdit">
                <a-tag color="green" v-if="userinfo.allow_sample">允许</a-tag>
                <a-tag v-else color="red">禁止</a-tag>
              </template>
            </a-form-item>
            <a-form-item label="专场报名" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-radio-group v-if="isRightEdit" :options="userRightOptions" v-model="allow_special" />
              <template v-if="!isRightEdit">
                <a-tag color="green" v-if="userinfo.allow_special">允许</a-tag>
                <a-tag v-else color="red">禁止</a-tag>
              </template>
            </a-form-item>
            <a-form-item label="优选商品" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-radio-group v-if="isRightEdit" :options="userRightOptions" v-model="allow_kuran_selected" />
              <template v-if="!isRightEdit">
                <a-tag color="green" v-if="userinfo.allow_kuran_selected">允许</a-tag>
                <a-tag v-else color="red">禁止</a-tag>
              </template>
            </a-form-item>
            <a-form-item label="登录折一" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-radio-group v-if="isRightEdit" :options="userRightOptions" v-model="is_active" />
              <template v-if="!isRightEdit">
                <a-tag color="green" v-if="userinfo.is_active">允许</a-tag>
                <a-tag v-else color="red">禁止</a-tag>
              </template>
              <div v-if="isRightEdit">
                <a-button @click="handleSwithUserRightStatus" class="mr-20">取消</a-button>
                <a-button type="primary" @loading="sending" @click="handleSaveRight">保存</a-button>
              </div>
            </a-form-item>
          </a-form>
        </a-card>
      </a-tab-pane>
    </a-tabs>
    <!-- 编辑财务信息 -->
    <a-modal
      class="detail-dialog bank-dialog"
      title="财务信息"
      v-model="bankDialogVisiable"
      centered
      okText="保存"
      cancelText="取消"
      :width="300"
      :okButtonProps="{ props: { loading: bankSaving } }"
      @ok="handleEditBankDialogOkClick"
    >
      <a-form :form="bankForm">
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-input
            v-decorator="['bank', { rules: [{ required: true, message: '请输入所属银行' }] }]"
            placeholder="所属银行"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-input
            v-decorator="[
              'bank_card_num',
              { rules: [{ required: true, message: '请输入银行卡号' }] },
            ]"
            placeholder="银行卡号"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-input
            v-decorator="['opening_bank', { rules: [{ required: true, message: '请输入开户行' }] }]"
            placeholder="开户行"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 全部收货地址弹窗 -->
    <a-modal title="收货信息" v-model="allAddressDialogVisiable" centered :footer="null">
      <a-list>
        <a-list-item v-for="(address, index) in addressList" :key="index">
          <a-list-item-meta>
            <div slot="title">{{ address.realname }} {{ address.mobile }}</div>
            <div slot="description">
              {{ address.province }} {{ address.city }} {{ address.area }} {{ address.address }}
            </div>
          </a-list-item-meta>
          <a slot="actions" v-if="address.default">默认地址</a>
        </a-list-item>
      </a-list>
    </a-modal>
    <!--用户数据-->
    <DrawerChartDetail
      v-if="isDrawerChartDetailShow"
      :drawerType="current_chart_detail_type"
      :union_id="current_union_id"
      @callback="hideDrawerChartDetail"
    />
    <!--用户等级-->
    <ModalLevelChange
      v-if="isModalLevelChangeShow"
      :data="userinfo"
      :union_id="current_union_id"
      @callback="handleModalLevelChangeHide"
    />
    <!--变更历史-->
    <DrawerChangeList
      v-if="isDrawerChangeListShow"
      :user="userinfo.id"
      @callback="handleDrawerChangeListHide"
    />
    <!--基本信息-->
    <ModalUserBaseInfoData
      v-if="isModalUserBaseInfoShow"
      :data="userinfo"
      :opeartors="opeartors"
      @callback="handleModalUserBaseInfoHide"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: '访问时间',
    dataIndex: 'create_time',
  },
  {
    title: '折一客户端',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: '设备',
    dataIndex: 'imei',
  },
  {
    title: 'IP',
    dataIndex: 'ip',
  },
];
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import {
  getUser,
  getUserInterviewRecord,
  editUserInfo,
  saveAddress,
  getAddressList,
  editAddress,
  patchUserRight,
  patchUserUnbind,
} from '../../service/user';

import { DEVICE_TYPE, GENDER_CODE } from '../../const/index';
// import { CITY_LIST } from "../../const/city";
import { checkTbkPid, checkPhoneNumber } from '../../utils/validator';
import DrawerChartDetail from '@/components/admin/user/drawer-chart-detail.vue';
import ModalLevelChange from '@/components/admin/user/modal-level-change.vue';
import DrawerChangeList from '@/components/admin/user/drawer-change-list.vue';
import ModalUserBaseInfoData from '@/components/admin/user/modal-user-base-info-data.vue';

export default {
  name: 'UserDetail',
  props: {
    opeartors: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
    },
  },
  components: {
    DrawerChartDetail,
    ModalLevelChange,
    DrawerChangeList,
    ModalUserBaseInfoData,
  },
  data() {
    return {
      // 是否显示用户详情图表
      isDrawerChartDetailShow: false,
      // 用户权限选项
      userRightOptions: [
        { label: '允许', value: true },
        { label: '禁止', value: false },
      ],
      // 是否编辑用户权限
      isRightEdit: false,
      userinfo: {},
      // 用户基本信息
      baseInfoDialogVisible: false,
      baseInfoLoading: false,
      baseInfoForm: this.$form.createForm(this),
      checkTbkPid,
      checkPhoneNumber,
      // 地址
      addressDialogVisiable: false,
      addressDialogType: 'add', // edit
      addressForm: this.$form.createForm(this),
      // addressOption: CITY_LIST,
      addressList: [],
      addressLoading: false,
      edittingAddressId: null,
      addressSaving: false,
      allAddressDialogVisiable: false,
      // 财务
      bankDialogVisiable: false,
      bankSaving: false,
      bankForm: this.$form.createForm(this),
      // 其他
      columns,
      interviewRecord: [],
      recordLoading: false,
      pagination: {
        size: 'small',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total => `共 ${total} 条`, //  第 ${this.pagination.current}/${Math.ceil(total / this.pagination.pageSize)} 页
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
      // 用户权限
      // 寄样申请
      allow_sample: false,
      // 登录折一
      is_active: false,
      // 专场报名
      allow_special: false,
      // 折一优选
      allow_kuran_selected: false,
      sending: false,
      current_union_id: null, // 抖音union_id
      isModalLevelChangeShow: false, // 修改评级modal状态
      isDrawerChangeListShow: false, // 变更记录drawer状态
      isModalUserBaseInfoShow: false, // 基本信息弹层显示
      current_chart_detail_type: 'detail', // 图表详情类型
    };
  },
  computed: {
    // 性别
    genderName() {
      if (this.userinfo.gender) {
        if (this.userinfo.gender === GENDER_CODE.MALE.VALUE) return GENDER_CODE.MALE.TEXT;
        if (this.userinfo.gender === GENDER_CODE.FEMALE.VALUE) return GENDER_CODE.FEMALE.TEXT;
      }
      return '';
    },
    // 默认地址
    defaultAddress() {
      const defaultAddress = this.addressList.find(item => item.is_default);
      return defaultAddress;
    },
  },
  mounted() {
    if (this.$props.id) {
      this.pagination.current = 1;
      this.init(this.$props.id);
    }
  },
  methods: {
    // 组件初始化，请求第一个tab的数据
    async init(id) {
      // 初始化访问记录分页
      this.pagination.total = undefined;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;

      // 获取用户基本信息
      this.getUserDetail(id);
      // 获取用户的访问记录
      this.getUserInterviewRecord(id);
      // 获取地址列表
      this.getAddressList(id);
    },
    // 获取性别
    getSexStr(gender) {
      let sexStr = '';
      switch (gender) {
        case 1:
          sexStr = '男性';
          break;
        case 2:
          sexStr = '女性';
          break;
        default:
          sexStr = '未知';
          break;
      }
      return sexStr;
    },
    // 企业号类型
    accountType(code) {
      let accountType = '';
      switch (code) {
        case 'EAccountM':
          accountType = '普通企业号';
          break;
        case 'EAccountS':
          accountType = '认证企业号';
          break;
        case 'EAccountK':
          accountType = '品牌企业号';
          break;
        default:
          accountType = '';
          break;
      }
      return accountType;
    },
    // 编辑用户权限
    handleSwithUserRightStatus() {
      this.isRightEdit = !this.isRightEdit;
      this.is_active = this.userinfo.is_active;
      this.allow_sample = this.userinfo.allow_sample;
      this.allow_special = this.userinfo.allow_special;
      this.allow_kuran_selected = this.userinfo.allow_kuran_selected;
    },
    // 获取用户详情
    async getUserDetail(id) {
      this.baseInfoLoading = true;
      const { err, res } = await getUser(id);
      if (!err) {
        this.userinfo = res.data;
        this.is_active = res.data.is_active;
        this.allow_sample = res.data.allow_sample;
        this.allow_special = res.data.allow_special;
        this.allow_kuran_selected = res.data.allow_kuran_selected;
        if (this.userinfo.photo && this.userinfo.back_photo) {
          this.$nextTick(() => {
            // 初始化图片查看器
            const viewer1 = new Viewer(document.getElementById('id-card-1'), {
              navbar: false,
              toolbar: false,
              hidden() {
                viewer1.destroy();
              },
            });
            document.getElementById('id-card-1-btn').onclick = () => viewer1.show();
            const viewer2 = new Viewer(document.getElementById('id-card-2'), {
              navbar: false,
              toolbar: false,
              hidden() {
                viewer2.destroy();
              },
            });
            document.getElementById('id-card-2-btn').onclick = () => viewer2.show();
          });
        }
      }
      this.baseInfoLoading = false;
    },
    // 获取用户的访问记录
    async getUserInterviewRecord(id) {
      this.recordLoading = true;
      const { current, pageSize } = this.pagination;
      const { err, res } = await getUserInterviewRecord({
        user: id,
        page: current,
        page_size: pageSize,
      });
      if (!err) {
        if (res.success) {
          this.interviewRecord = res.data.results;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      }
      this.recordLoading = false;
    },

    // 点击显示基本信息
    handleBaseInfoEditClick() {
      this.isModalUserBaseInfoShow = true;
    },
    // 隐藏基本信息
    handleModalUserBaseInfoHide() {
      this.isModalUserBaseInfoShow = false;
      this.init(this.$props.id);
    },
    // 显示设备名称
    showDeviceName(project) {
      let name = '';
      for (let key in DEVICE_TYPE) {
        if (DEVICE_TYPE[key].VALUE === project) {
          name = DEVICE_TYPE[key].TEXT;
          break;
        }
      }
      return name;
    },
    // 访问记录翻页
    hanleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getUserInterviewRecord(this.$props.id);
    },
    // 点击新增地址 （功能移除）
    // handleAddressAddClick () {
    //   this.addressDialogType = 'add'
    //   this.addressDialogVisiable = true
    //   this.$nextTick(() => {
    //     this.addressForm.resetFields()
    //   })
    // },
    // 新增修改收货地址弹窗
    handleEditAddressDialogOkClick() {
      this.addressForm.validateFields(async (error, values) => {
        if (!error) {
          const { realname, mobile, address, city } = values;
          const data = {
            realname,
            mobile,
            address,
            user: this.userinfo.id,
            default: false,
          };
          data.province = city[0];
          data.city = city[1];
          data.area = city[2];
          //
          this.addressSaving = true;
          // 新增地址
          if (this.addressDialogType === 'add') {
            const { err, res } = await saveAddress(data);
            if (!err) {
              if (res.success) {
                this.$message.success('收货地址保存成功');
                this.addressDialogVisiable = false;
                this.addressForm.resetFields();
                // 获取地址列表
                this.getAddressList(this.userinfo.id);
              }
            }
          } else if (this.addressDialogType === 'edit') {
            const { err, res } = await editAddress(this.edittingAddressId, data);
            if (!err) {
              if (res.success) {
                this.$message.success('收货地址保存成功');
                this.addressDialogVisiable = false;
                this.addressForm.resetFields();
                this.edittingAddressId = null;
                // 获取地址列表
                this.getAddressList(this.userinfo.id);
              }
            }
          }
          this.addressSaving = false;
        }
      });
    },
    // 获取收货地址列表
    async getAddressList(user) {
      this.addressLoading = true;
      const { err, res } = await getAddressList({ user });
      if (!err) {
        if (res.success) {
          this.addressList = res.data.results;
        }
      }
      this.addressLoading = false;
    },
    // 点击
    handleAddressReviewClick() {
      this.allAddressDialogVisiable = true;
    },
    // 点击编辑地址按钮
    // handleAddressEditClick (address) {
    //   this.addressDialogType = 'edit'
    //   this.edittingAddressId = address.id
    //   const addressDetail = JSON.parse(JSON.stringify(address))
    //   this.addressDialogVisiable = true
    //   addressDetail.city = [address.province, address.city, address.area]
    //   this.$nextTick(() => {
    //     this.addressForm.setFieldsValue(addressDetail)
    //   })
    // },
    // 编辑财务信息弹窗确认按钮点击事件
    handleEditBankDialogOkClick() {
      this.bankForm.validateFields(async (error, values) => {
        if (!error) {
          const { err, res } = await editUserInfo(this.$props.id, values);
          if (!err) {
            if (res.success) {
              this.$message.success('财务信息保存成功');
              this.bankDialogVisiable = false;
              this.bankForm.resetFields();
              // 更新数据
              this.userinfo.bank = values.bank;
              this.userinfo.bank_card_num = values.bank_card_num;
              this.userinfo.opening_bank = values.opening_bank;
            }
          }
        }
      });
    },
    // 点击编辑财务信息
    handleBankEditClick() {
      this.bankDialogVisiable = true;
      this.$nextTick(() => {
        this.bankForm.setFieldsValue(this.userinfo);
      });
    },
    // 保存用户权限
    async handleSaveRight() {
      const data = {
        id: this.id,
        is_active: this.is_active,
        allow_sample: this.allow_sample,
        allow_special: this.allow_special,
        allow_kuran_selected: this.allow_kuran_selected,
      };
      this.sending = true;
      const { err, res } = await patchUserRight(data);
      this.sending = false;
      if (!err) {
        if (res.success) {
          this.$message.success(res.message);
          this.userinfo.is_active = this.is_active;
          this.userinfo.allow_sample = this.allow_sample;
          this.userinfo.allow_special = this.allow_special;
          this.userinfo.allow_kuran_selected = this.allow_kuran_selected;
          this.isRightEdit = false;
        }
      }
    },
    // 显示用户详情图表chart
    handleShowUserChart() {
      this.current_union_id = this.userinfo.auth && this.userinfo.auth.unionid;
      this.current_chart_detail_type = 'detail';
      this.isDrawerChartDetailShow = true;
    },
    // 隐藏用户详情图表chart
    hideDrawerChartDetail() {
      this.isDrawerChartDetailShow = false;
    },
    // 显示绑定历史用户详情图表chart
    showBindHistoryDrawer(unionid) {
      this.current_union_id = unionid;
      this.current_chart_detail_type = 'history';
      this.isDrawerChartDetailShow = true;
    },
    // 修改达人对接
    handleChangeStartDockingUser(e) {
      this.userinfo.star_docking_user = e;
    },
    // 修改运营对接
    handleOperateDockingUser(e) {
      this.userinfo.operate_docking_user = e;
    },
    // 人员id转字符串
    personIdToStr(pid) {
      const i =
        this.opeartors && this.opeartors.length
          ? this.opeartors.find(({ id }) => id === pid)
          : { username: '', id: null };
      return i && i.username;
    },
    // 显示修改评级modal
    handleModalLevelChangeShow() {
      this.isModalLevelChangeShow = true;
    },
    // 隐藏修改评级modal
    handleModalLevelChangeHide() {
      this.isModalLevelChangeShow = false;
      this.init(this.id);
    },
    // 显示变更记录
    handleDrawerChangeListShow() {
      this.isDrawerChangeListShow = true;
    },
    // 隐藏变更记录
    handleDrawerChangeListHide() {
      this.isDrawerChangeListShow = false;
    },
    // 解除绑定确认
    handleUnbind() {
      const that = this;
      this.$confirm({
        title: '解除绑定',
        content: () => (
          <div>
            确认要解除抖音号【 {this.userinfo && this.userinfo.auth && this.userinfo.auth.nickname}{' '}
            】和用户的绑定关系？
          </div>
        ),
        async onOk() {
          const data = { user_id: that.userinfo.id };
          const { err, res } = await patchUserUnbind(data);
          if (!err && res.success) {
            that.$message.success(res.message);
            that.init(that.userinfo.id);
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
#user-detail {
  .user-info {
    .user-info-line {
      line-height: 30px;
    }
  }
  .id-card {
    width: 268px;
    height: 196px;
    cursor: pointer;
  }
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.bind-history {
  margin-top: 20px;
}
</style>
